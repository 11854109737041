import React, {ReactNode, useEffect} from 'react';
import {useRouteChange} from "./Index";
import {useEffectState} from "../hooks/useEffectState";

type IProps = {
    children: ReactNode
}
export default function Switch(props: IProps) {
    const { pathname } = useRouteChange();
    const state = useEffectState({
        element: null as any,
    });
    useEffect(() => {
        let match = false;
        //document.documentElement.scrollTop = 0;
        React.Children.forEach( props.children ,async (item: any) => {
            let matchPath = item.props.path === pathname || (!item.props.exact && pathname.startsWith(item.props.path));
            if (!match && matchPath) {
                match = true;
                if (item.props.beforeRender) {
                    await item.props.beforeRender();
                }
                state.element = item;
            }
        })
    }, [pathname]);

    return <>
        {state.element ? React.cloneElement(state.element) : null }
    </>
}
