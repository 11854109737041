import {useState} from 'react';

export function useEffectState<T extends Object>(initData: T): T {
    const [data, setData] = useState(initData);

    const copyData = Object.assign({}, data);
    for (let variable in data) {
        if (data.hasOwnProperty(variable)) {
            Object.defineProperty(data, variable, {
                set(value) {
                    copyData[variable] = value;
                    setData(copyData);
                    return value;

                },
                get() {
                    return copyData[variable];
                }
            })
        }
    }
    return data;
}
