const isProduction = process.env.NODE_ENV === "production";

export const nft = require('./nft.json');
export const erc20 = require('./erc20.json');
export const tempProject = require('./project.json')

for (let _chain in tempProject.policysMap) {
    tempProject.policysMap[_chain].map((item: any) => {
        const _source = tempProject.policys.filter((s_item: any) => s_item.token === item.token)[0]
        item.maxBenefit = _source.maxBenefit
        item.benifits = _source.benifits
        item.showAPY = _source.showAPY
        item.showStakeAPY = _source.showStakeAPY
    })
}

export const project = tempProject;
export const pair = require('./pair.json');

export const chainID = project.chainid;
export const chainNode = project.node;
export const blockExplorer = project.node;
export const mainSymbol = project.chainSymbol;
export const mainSymbolDecimals = project.chainSymbolDecimal;

export const swapURL = project.swapURL || "https://pancake.kiemtienonline360.com";

export const registeredCheckAPI = "https://board.defilabs.farm/memberapi/member/is_bsc_address_registered"
export const skipLinkRegister = "https://board.defilabs.farm/register"

export const precision = 6;

export const MaxApproveBalance = "500000000000000000000000";
export const minAllowance = "50000000000000000000";
export const zeroAddress = "0x0000000000000000000000000000000000000000";

export const ADMIN_ADDRESS = "0xc783df8a850f42e7F7e57013759C285caa701eB6";
export function isAdmin(address: string) {
    return ADMIN_ADDRESS.toLocaleLowerCase() === address.toLocaleLowerCase();
}

export const ROIS = {
    CAKE: [
        { day: "0", ROI: '108.41', Per: "1,084.05" },
        { day: "30", ROI: '111.42', Per: "1,114.16" },
        { day: "90", ROI: '114.43', Per: "1,144.28" },
    ],
    BNB: [
        { day: "0", ROI: '130.49', Per: "1,304.88" },
        { day: "30", ROI: '129.48', Per: "1,294.84" },
        { day: "90", ROI: '132.50', Per: "1,324.95" },
    ],
    USDT: [
        { day: "0", ROI: '180.68', Per: "1,806.75" },
        { day: "30", ROI: '183.69', Per: "1,836.86" },
        { day: "90", ROI: '186.70', Per: "1,866.98" },
    ],
    BUSD: [
        { day: "0", ROI: '180.68', Per: "1,806.75" },
        { day: "30", ROI: '183.69', Per: "1,836.86" },
        { day: "90", ROI: '186.70', Per: "1,866.98" },
    ],
    BTC: [
        { day: "0", ROI: '57.21', Per: "572.14" },
        { day: "30", ROI: '60.23', Per: "602.25" },
        { day: "90", ROI: '63.24', Per: "632.36" },
    ],
    ETH: [
        { day: "0", ROI: '78.29', Per: "782.93" },
        { day: "30", ROI: '81.30', Per: "813.04" },
        { day: "90", ROI: '84.32', Per: "843.15" },
    ]
};

export function apyFormula(apy: number, random: number) {
    return apy * 0.9 + (random * apy * 0.19)
}

export function getTypeRevert(day: number) {
    switch (day) {
        case 0:
            return 0;
        case 30:
            return 1;
        case 90:
            return 2;
    }
}

export function getType(type: number) {
    switch (type) {
        case 0:
            return 1;
        case 1:
            return 30;
        case 2:
            return 90;
    }
}

export function isMainSymbol(tokenName: string, chainID: string) {
    const _current = project.policysMap[chainID].filter((item: any) => (item.token || '').toLocaleLowerCase() === (tokenName || '').toLocaleLowerCase())[0]
    // return mainSymbol.toLocaleLowerCase() === tokenName.toLocaleLowerCase();
    return _current.isMain
}

export function getPairName(token0Name: string, token1Name: string) {
    const name1 = `${token0Name}_${token1Name}_Pair`;
    const name2 = `${token1Name}_${token0Name}_Pair`;
    if (project.contracts.hasOwnProperty(name1)) {
        return name1;
    } else {
        return name2;
    }
}

export const homePageLink = "https://defilabs.farm/"
// export const homePageLink = "http://47.75.121.188:9999/"






