import React from 'react';
import { $router, ReactRouter } from 'src/react-router-perfect/Index';
import { Iroutes } from "../react-router-perfect/types";
import { isSameAddress } from "../contract/wallet";
import { project } from "../contract/config";
import { useStore } from "react-redux";
// import useWidthChange from "src/hooks/useWidthChange";
// const { isH5 } = useWidthChange();
const Home = () => import(/* webpackChunkName: "index" */"src/views/home/Home");
const Page = () => import(/* webpackChunkName: "index" */"src/views/page/Page");
const Introduce = () => import(/* webpackChunkName: "index" */"src/views/introduce/introduce");
const Defilabs = () => import(/* webpackChunkName: "index" */"src/views/defilabs/introduce");
const Defilpledge = () => import(/* webpackChunkName: "index" */"src/views/defilpledge/introduce");
//const Profit = () => import(/* webpackChunkName: "profit" */"src/views/profit/Profit");
const Stake = () => import(/* webpackChunkName: "stake" */"src/views/stake/Stake");
const Admin = () => import(/* webpackChunkName: "stake" */"src/views/admin/Admin");

export default function RouterView() {
    const store = useStore();
    const storeData = store.getState();

    const routes: Iroutes[] = [
        {
            path: "/farm",
            component: Home,
            exact: true,
            isLazy: true
        },
        {
            path: "/home",
            component: Page,
            exact: true,
            isLazy: true
        },
        {
            path: "/admin",
            component: Admin,
            isLazy: true,
            beforeRender(): void {
                if (!isSameAddress(project.funder[storeData.chainId], storeData.address)) {
                    $router.push({ pathname: "/" })
                }
                /*$router.push({pathname: "/admin"})*/
            }
        },
        {
            path: "/stake",
            component: Stake,
            isLazy: true
        },
        {
            path: "/introduce",
            component: Introduce,
            exact: true,
            isLazy: true
        },
        {
            path: "/defilabs",
            component: Defilabs,
            exact: true,
            isLazy: true
        },
        {
            path: "/defilpledge",
            component: Defilpledge,
            exact: true,
            isLazy: true
        },
        {
            path: "/",
            component: Page,
            exact: true,
            isLazy: true
        },
    ];

    return (
        <ReactRouter routes={routes}></ReactRouter>
    )
}
