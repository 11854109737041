import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import * as style from "./announcement.style";
import * as stylePC from "./announcement.pc.style";
import UseWidthChange from "src/hooks/useWidthChange";

export default function SysTip(props: any) {
  const { t, i18n } = useTranslation();
  const { isH5 } = UseWidthChange();
  const { SysTipStyle } = isH5 ? style : stylePC;
  const isURL = /^https?:\/\//.test(t('noticeF'));
  console.log('language=', i18n.language);

  function openHandler(event: any) {
    let openType = 1;
    if (event.target.tagName.toLocaleLowerCase() === 'span') {
      openType = event.target.dataset.type
      const lang = i18n.language
      const _map: any = {
        1: {
          en: 'DefiLabs Privacy Policy.pdf',
          zh: 'DefiLabs 隐私政策.pdf',
        },
        2: {
          en: 'DefiLabs Service Agreement.pdf',
          zh: 'DefiLabs 服务协议.pdf',
        },
      }
      let local = 'en'
      if (lang === 'zh_CN') {
        local = 'zh'
      }
      window.open(`/doc/${_map[openType][local]}`, "_bank")
    }
  }

  function aggressHandler() {
    localStorage.setItem('announcement-new', '1')
    props.aggressHandler()
  }

  return (
    <SysTipStyle>
      { i18n.language === 'ja_JP' ? <div className="global-sys-tip">
        <div className="content">
          <p className="title">📢 【サービス再開のお知らせ】 📢</p>
          <p>最新のアップデートは、セキュリティと利便性を両立しています🔐🔥</p>
          <p>ウェブサイトを訪問する：<a href="https://dflfarm.net/">https://dflfarm.net/</a></p>
          <p>📅 2023年8月25日</p>
          <p>1. 脆弱性の強化</p>
          <p>脆弱性を入念に修正し、スマートコントラクトにおけるセキュリティ強化しました。</p>
          <p>2. 防御的プログラミング</p>
          <p>資金を保全する防御的プログラミング強化しました。</p>
          <p>3. 強化されたセキュリティ</p>
          <p>最高水準のセキュリティ強化により、資産が最大の注意で保護されます。</p>
          <p>4. リニューアルされたUI</p>
          <p>プラットフォームのリフレッシュされたインターフェースでシームレスに利用ができます。</p>
          <p>5. ガス料金の削減：ガス料金の削減により、トランザクションがより効率的になります。</p>
          <p>6. リステイク機能の導入：リワードを簡単に再投資し、投資の成長を加速させてください。</p>
          <p>7. 新しい部分的な引き出し機能：一部のステークを簡単に引き出し、残りは引き続きリワードを獲得できます。</p>
          <p>8. 部分的なリステイク機能：一部のリワードを再投資して収益を最大化できる柔軟性を高めます。</p>
          <p>9. シームレスな追加ステーキング：長期ステーキングプールに資金を追加しても、ステーキングクロックを再起動することなく収益を最大化できます。</p>
          <p>10. 高い年間利回り率（APR）の提供：増加した年間利回り率（APR）で資産形成を加速させます。</p>
          <p>11. 新しい180日プール：延長された180日のステーキングプールを通じて成長を持続し、収益を最大化します。</p>
          <p>私たちは改善を終わり無く続けており、このアップデートはエキサイティングな旅の始まりを告げています！今日私たちと一緒に収益体験を向上させしましょう！❗️🔽🔽</p>
          <p>ご質問は、専任のサポートチームがお手伝いいたします。👩‍💻 support@defilabs.farm</p>
          <p>私たちと一緒にステーキングの未来を明らかにしましょう！🚀🌟</p>
        </div>
        <div className="btn-wrap">
          <button onClick={() => aggressHandler()}>{t('关闭')}</button>
        </div>
      </div> : <div className="global-sys-tip">
        <p className="title">📢 {t('noticeA')} 📢</p>
        <p>{t('noticeB')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeC')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeD')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeE')}</p>
        {isURL ? (
          <a href={t('noticeF')} target="_blank"><p style={{ marginTop: "16px" }}>{t('noticeF')}</p></a>
        ) : (
          <p style={{ marginTop: "16px" }}>{t('noticeF')}</p>
        )}
        <p style={{ marginTop: "16px" }}>{t('noticeG')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeH')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeI')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeJ')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeK')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeL')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeM')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeN')}</p>
        <p style={{ marginTop: "16px" }}>{t('noticeO')}</p>
        <div className="btn-wrap">
          <button onClick={() => aggressHandler()}>{t('关闭')}</button>
        </div>
      </div> }
      {/* <div className="global-sys-tip">
        <p className="title">{t('公告')}</p>
        <p>{t('由于目前BUSD存在许多不稳定因素，为规避潜在风险，保护DefiLabs成员的资产安全，DefiLabs建议成员取消BUSD质押，转为USDT进行质押。')}</p>
        <p>{t('自 2023 年 3 月 1 日起，BUSD质押池将不再接受新的质押。对于正在质押BUSD且质押尚未到期的用户，本次变更不影响收益及收益的正常提取，也不影响期满后的投资提现。')}</p>
        <p>{t('确保用户资金安全是DefiLabs的重中之重。')}</p>
        <p style={{marginTop: "16px"}}>{t('DefiLabs启')}</p>
        <div className="btn-wrap">
            <button onClick={() => aggressHandler()}>{t('关闭')}</button>
        </div>
      </div> */}
    </SysTipStyle >
  );
}
