import {TFunction} from "i18next";
import {project} from "./contract/config";

//export const service_api = "http://192.168.1.88:8888/";
export const service_api = "http://18.162.49.224:8082/";

export const PROVIDER = window['ethereum'];

export const isProduction = process.env.NODE_ENV === "production";

export function getAuctionType(type: number, t: TFunction) {
    switch (type) {
        case 0:
            return t(`英式`);
        default:
            return t(`荷兰式`);
    }
}

export function getBenefitIndex(amount: number): number {
    let gIndex = 0;
    project.policysRegion.forEach((item: any, index: number) => {
      if (amount >= item.min && (item.max === -1 || amount < item.max)) {
          gIndex = project.policysRegion.length - (index + 1);
      }
    });
    return gIndex;
/*
    if (amount >= 20000) {
        return 6;
    } else if (amount >=10000 && amount < 20000) {
        return 5;
    }  else if (amount >= 5000 && amount < 10000) {
        return 4;
    } else if (amount >= 3000 && amount < 5000) {
        return 3;
    } else if (amount >= 1000 && amount < 3000) {
        return 2;
    } else if (amount >= 500 && amount < 1000) {
        return 1;
    } else {
        return 0; //[100, 500)
    }*/
}

/*export function getBenefitIndex(amount: number): number {
    if (amount >= 200) {
        return 6;
    } else if (amount >=100 && amount < 200) {
        return 5;
    }  else if (amount >= 50 && amount < 100) {
        return 4;
    } else if (amount >= 30 && amount < 50) {
        return 3;
    } else if (amount >= 10 && amount < 30) {
        return 2;
    } else if (amount >= 5 && amount < 10) {
        return 1;
    } else {
        return 0; //[1, 5)
    }
}*/
