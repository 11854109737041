import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import * as style from './Prompt.style';
import * as stylePC from './Prompt.style.pc';
import { useEffectState } from 'src/hooks/useEffectState';
import useWidthChange from 'src/hooks/useWidthChange';
import { homePageLink, skipLinkRegister } from 'src/contract/config';
import {
  bindCodeApi,
  createAccount,
  rowVersion,
  connectWallet
} from 'src/ajax/index';
import { showMessage } from 'src/common/utilTools';
import { BigNumber, ethers, utils } from 'ethers';
type IProps = {
  hideMsg?: boolean;
  style?: CSSProperties;
  hrefer?: Number;
  address?: any;
  hideHandler?: any;
};
export default function Foot(props: IProps) {
  const { t, i18n } = useTranslation();
  const { isH5 } = useWidthChange();
  const { FootStyle } = isH5 ? style : stylePC;
  const store = useStore();
  const storeData = store.getState();
  const state = useEffectState({
    active: 1
  });
  const [codeValue, setCode] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { hrefer, address } = props;
  function nametitle(value: number) {
    // console.log(value);

    state.active = value;
  }

  async function bindCodeHandler() {
    if (!codeValue) {
      showMessage(t('请输入邀请码'));
      return;
    }
    if (!username) {
      showMessage(t('请输入用户名'));
      return;
    }
    if (!password) {
      showMessage(t('请输入密码'));
      return;
    }
    if (password !== confirmPassword) {
      showMessage(t('两次密码不一致'));
      return;
    }
    const { chainId, address } = storeData;
    const rowRes: any = await rowVersion(chainId, address);
    const _signature = await new ethers.providers.Web3Provider(
      window.ethereum,
      'any'
    )
      .getSigner()
      .signMessage(
        `Your verification code is ${address.toLocaleLowerCase()}-${rowRes.data.toLocaleLowerCase()}`
      );
    console.log(_signature);
    await connectWallet(chainId, address, _signature);
    createAccount(codeValue, username, password, storeData.lang)
      .then((res: any) => {
        if (props.hideHandler) {
          props.hideHandler();
        }

        localStorage.setItem(`reg-${address}`, 'yes');
        //sessionStorage.removeItem('isRegistered')
        showMessage(t('注册成功'));
        // props.hideHandler&&props.hideHandler()
        // setTimeout(() => {
        //     window.location.reload()
        // }, 1000)
      })
      .catch((err) => {
        console.log(JSON.parse(JSON.stringify(err)));
        showMessage(err.message);
      });
    // bindCodeApi(codeValue, storeData.chainId, storeData.address).then((res:any) => {
    //     // console.log(res)
    //     if(res.success){
    //         if(props.hideHandler){
    //             props.hideHandler()
    //         }
    //         showMessage(t("绑定成功"))
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 1000)
    //     }else{
    //         showMessage(res.msg)
    //     }
    // })
  }
  return (
    <FootStyle>
      <div className={'center'}>
        <div>{t('注册')}</div>
        <div></div>
        <div className="input-wrap">
          <span>{t('用户名')}</span>
          <input
            className="text"
            type="text"
            value={username}
            onInput={(el: any) => setUsername(el.target.value)}
          />
        </div>
        <div className="input-wrap">
          <span>{t('密码')}</span>
          <input
            className="text"
            type="password"
            value={password}
            onInput={(el: any) => setPassword(el.target.value)}
          />
        </div>
        <div className="input-wrap">
          <span>{t('确认密码')}</span>
          <input
            className="text"
            type="password"
            value={confirmPassword}
            onInput={(el: any) => setConfirmPassword(el.target.value)}
          />
        </div>
        <div className="input-wrap">
          <span>{t('邀请码')}</span>
          <input
            className="text"
            type="text"
            value={codeValue}
            onInput={(el: any) => setCode(el.target.value)}
          />
        </div>
        <button className={'buttonhref'} onClick={() => bindCodeHandler()}>
          {t('注册')}
        </button>
      </div>
    </FootStyle>
  );
}
