import styled from "styled-components";
import { colors } from "../../styles/style";

export const FootStyle = styled.div`
width: 100%;
height: 100%;
display: flex;
z-index: 999;
background-color: rgba(0,0,0,.7);
justify-content: center;
align-items: center;
position: fixed;
    .center{
       
        height: 520px;
        background:#191B28;
        border-radius: 8px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 14px;
        .input-wrap{
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
        .text{
            width: 100%;
            height: 32px;
            background: rgba(255,255,255,0.12);
            border: 1px solid #EFE105;
            padding-left: 18px;
            box-sizing: border-box;
            outline: none;
            color: #fff;
            border-radius: 8px;
        }
        div{
            padding: 0 20px;
        }
    }
    .buttonhref{
        margin-top: 27px;
        border-radius: 12px;
        background: #FFEB00;
        width: 133px;
        height: 36px;
        border: none;
        font-size: 12px;
        font-weight: 800;
    }
`;

