export enum ConvertType {
    pending = 1,
    success = 2,
    fail
}

export const ChainId: any = {
    "1": "Ethereum",
    "56": "BSC",
    "88": "TRON",
}

export enum LanguageType {
    en = "en_US",
    zh = "zh_CN",
    ft = "zh_CHT",
    ja = "ja_JP",
    ko = "ko_KR",
    yd = "Id_N",
    ty = "Id_N",
}
export const LanguageShow: any = {
    "en_US": "EN",
    "zh_CN": "中文",
    "zh_CHT": "繁体",
    "ja_JP": "日本語",
    "ko_KR": "한국인",
    "Id_N": "Indonesia",
    // "tland": "ไทย",
    // "russian": "Русский",
    // "arabic": "عربي",
    // "vietnamese": "Tiếng Việt",
}

export const BrowserLangMap: any = {
    'zh': 'zh_CN',
    'zh-cn': 'zh_CN',
    'zh-TW': 'zh_CHT',
    'zh-HK': 'zh_CHT',
    'en': 'en_US',
    'en-us': 'en_US',
    'ko': 'ko_KR',
    'ko-KR': 'ko_KR',
    'ja': 'ja_JP',
    'ja-jp': 'ja_JP',
    'id': 'Id_N'
}

export enum NftType {
    Master,
    Segment,
    Compose
}


export enum NftStatus {
    wait,
    pending,
    success,
    cancel
}
