import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import IAjax from './types';
import { Toast } from 'src/components/toast/Toast';
import { service_api } from 'src/config';
import { store } from 'src/store';
import { $router } from 'src/react-router-perfect/Index';
//预发布环境
// axios.defaults.baseURL = service_api;
axios.defaults.baseURL = '/web-api';
//=>设置请求头
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 30 * 1000;
interface myConfig extends AxiosRequestConfig {
  userConfig?: any;
}

const _langMap: any = {
  en_US: 'ENGLISH',
  zh_CN: 'SIMPLIFIED_CHINESE',
  zh_CHT: 'TRADITIONAL_CHINESE',
  ja_JP: 'JAPANESE',
  ko_KR: 'KOREAN',
  Id_N: 'BAHASA_INDO'
};
// =>设置请求拦截器
axios.interceptors.request.use(
  (config: myConfig) => {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    let showLoading = true;
    if (config.headers && config.headers.common) {
      let token = userInfo.token || sessionStorage.getItem('token');
      if (token) {
        config.headers.common['token'] = token;
      }
      let language = $router.query.lang || store.getState().lang;
      if ((config.url || '').search('../') === -1) {
        config.headers.common['Accept-Language'] = language.replace('_', '-');
      } else {
        delete config.headers.common['Accept-Language'];
        // config.headers.common['Accept-Language'] = undefined
        config.headers.common['Accept-Language'] = '';
        config.headers.common['Accepted-Language'] = _langMap[language];
      }
    }

    if (config.userConfig && config.userConfig.showLoading === false) {
      showLoading = false;
    }
    //loading加载多次会报错，暂时先这样
    if (
      showLoading &&
      document.getElementsByClassName('loading-box').length === 0
    ) {
      /*loadingInstance = Loading.loading({
                text: config.userConfig && config.userConfig.loadText || 'loading...'
            });*/
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// =>设置响应拦截器
axios.interceptors.response.use(
  (response: AxiosResponse<any>): Promise<any> => {
    if (response.config.url?.search('../') === -1) {
      if (response.status === 200) {
        if (response.data && !response.data.success) {
          Toast(response.data.message || (response.data as any).msg);
          /*重新登录*/
          if (response.data.code === 3) {
            /*let dispatchData = mapDispatchToProps(store.dispatch);
                        dispatchData.clearnLoginStatus();*/
          }
          return Promise.reject(response.data);
        }
      }
      return Promise.resolve(response.data);
    } else {
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    //注册接口特殊处理
    if (
      error.response.config.url ===
      'https://api.defilabs.farm/api/account/create'
    ) {
      return Promise.reject(error.response.data);
    }
    if (error.response.config.url.search('../') === -1) {
      Toast(error.toString());
      return Promise.reject(error);
    } else {
      return Promise.reject(error.response.data);
    }
  }
);

export { axios };
