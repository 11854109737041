import { useState, useEffect } from 'react';
import { axios } from './axios';
import IAjax from './types';
import { stringify } from 'src/common/utilTools';
import { Toast } from 'src/components/toast/Toast';
const qs = require('qs');
export interface reqConfig {
  postType?: string;
  headers?: any;
}

/**
 * 获取多链合约信息
 */
export function getContractAddress() {
  // console.log('contract --get ajax')
  return new Promise((resolve, reject) => {
    let _cache = sessionStorage.getItem('contractAddressMap');
    if (_cache) {
      resolve(JSON.parse(_cache));
    } else {
      axios
        .get('/web/contract')
        .then((res) => {
          sessionStorage.setItem('contractAddressMap', JSON.stringify(res));
          resolve(res);
        })
        .catch(() => {
          reject({
            err: 'get contract address err'
          });
        });
    }
  });
  // return axios.get('/web/contract')
}

/**
 * 检测是否注册用户
 */
export async function checkAddressApi(chainId: any, addr: any, lang?: any) {
  if (chainId && addr) {
    const ckey = `reg-${addr}`;
    return new Promise((resolve, reject) => {
      let _cache = localStorage.getItem(ckey);
      if (_cache) {
        resolve({ data: true });
      } else {
        addressIsBound(chainId, addr, lang)
          .then((res: any) => {
            const _res = {
              data: res.data ? res.data.has_sponsor : false
            };

            if (_res.data) {
              localStorage.setItem(ckey, 'yes');
            }

            resolve(_res);
          })
          .catch((err) => {
            reject(err);
          });
      }
      // let _cache = sessionStorage.getItem('isRegistered')
      // if (_cache) {
      //     resolve(JSON.parse(_cache))
      // } else {
      //     axios.post('/web/isRegistered', {}, {
      //         headers: {
      //             chainId,
      //             addr
      //         }
      //     }).then(res => {
      //         sessionStorage.setItem('isRegistered', JSON.stringify(res))
      //         resolve(res)
      //     }).catch(() => {
      //         reject({
      //             err: 'get isRegistered err'
      //         })
      //     })
      // }
    });
  } else {
    return new Promise((resolve, reject) => {
      Toast('not get address');
      reject({
        err: 'no chainId and address'
      });
    });
  }
}
/**
 * 绑定邀请码
 */
export function bindCodeApi(code: any, chainId: any, addr: any) {
  return axios.post(
    `/web/registry?code=${code}`,
    {},
    {
      headers: {
        chainId,
        addr
      }
    }
  );
}

export function fetchPost<R extends Object>(
  url: string,
  data = {},
  config: reqConfig = {}
): Promise<IAjax<R>> {
  if (data instanceof FormData) {
    //目前用于上传图片
    return axios.post(url, data, config);
  } else {
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    return axios.post(url, JSON.stringify(data), config);
  }
}

export function fetchGet<R extends Object>(
  url: string,
  data?: object,
  config?: object
): Promise<IAjax<R>> {
  let reqURL = data ? `${url}?${stringify(data)}` : url;
  return axios.get(reqURL, config);
}

interface IusePost {
  url: string;
  params?: Object;
  config?: Object;
  depend?: any[];
}
export function usePostArr<R>(params: IusePost): {
  data: R[];
  loading: boolean;
  error: string;
  total: number;
} {
  const [data, setData] = useState<R[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let { data, success, message } = await fetchPost<R[]>(
        params.url,
        params.params,
        params.config
      );
      setData(data);
      setLoading(false);
      setTotal(total);
      if (!success) {
        setError(message);
      }
    })();
  }, [...Object.values(params.depend || params.params || [])]);

  return { data, loading, error, total };
}

export function usePost<R extends Object>(
  params: IusePost
): { data: R; loading: boolean; error: string; total: number } {
  const [data, setData] = useState<R>({} as R);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let { data, success, message } = await fetchPost<R>(
        params.url,
        params.params,
        params.config
      );
      setData(data);
      setLoading(false);
      setTotal(total);
      if (!success) {
        setError(message);
      }
    })();
  }, [...Object.values(params.depend || params.params || [])]);

  return { data, loading, error, total };
}

/**
 * 1.	Check if wallet address has bound to a sponsor
 */
export function addressIsBound(chainId: any, addr: any, lang?: any) {
  if (chainId && addr) {
    return new Promise((resolve, reject) => {
      axios
        .get('https://api.defilabs.farm/api/account/check-wallet-status', {
          params: {
            network: 'BSC',
            Wallet_address: addr
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve({
            data: false
          });
          console.log('Failed to check wallet status.', err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      reject({});
    });
  }
}

/**
 * 2.	Get account row version
 */
export function rowVersion(chainId: any, addr: any, lang?: any) {
  if (chainId && addr) {
    return new Promise((resolve, reject) => {
      axios
        .get('https://api.defilabs.farm/api/account/rowversion', {
          params: {
            network: 'BSC',
            wallet_address: addr
          }
        })
        .then(async (res) => {
          sessionStorage.setItem('rowVersion', JSON.stringify(res));
          // connectWallet(chainId, addr, _signature, lang)
          resolve(res);
        })
        .catch(() => {
          reject({
            err: 'get rowVersion err'
          });
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  }
}

/**
 * 3.	Connect wallet
 */
export function connectWallet(
  chainId: any,
  addr: any,
  signature_data: any,
  lang?: any
) {
  if (chainId && addr) {
    return new Promise((resolve, reject) => {
      axios
        .post('https://api.defilabs.farm/api/account/connect', {
          network: 'BSC',
          wallet_address: addr,
          signature_data
        })
        .then((res) => {
          sessionStorage.setItem('connectWallet', res.data);
          resolve(res);
        })
        .catch(() => {
          reject({
            err: 'set connectWallet err'
          });
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  }
}

/**
 * 4.	Create new account
 */
export function createAccount(
  sponsor_username: any,
  username: any,
  password: any,
  lang: any
) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        'https://api.defilabs.farm/api/account/create',
        {
          sessionid: sessionStorage.getItem('connectWallet'),
          sponsor_username,
          username,
          password
        },
        {
          headers: {
            sessionid: sessionStorage.getItem('connectWallet')
          }
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
