import React from 'react';
import './App.css';
import Nav from './components/nav/Nav';
import RouterView from "./router/RouterView";
import { AppStyle } from "./styles/App.style";
import connect, { IConnectProps } from "./store/connect";
import plusReady from "./contract/plusReady";
import { ethers } from "ethers";
import { chainID, project, registeredCheckAPI } from "./contract/config";
import { Toast } from "./components/toast/Toast";
import { withTranslation, WithTranslation } from "react-i18next";
import { isProduction, PROVIDER } from "./config";
import ConnectWallet from './components/ConnectWallet';
import Toggle from "./components/toggle/Toggle";
import PubSub from "pubsub-js";
import { ITrans } from "./contract/types";
import TransModal from "./transModal/TransModal";
import { log } from "util";
import { fetchGet } from "src/ajax/index"
import { checkAddressApi } from "src/ajax/index";
import Promptbox from './components/promptbox/Promptbox';
import SysTip from './components/sysTip/SysTip';
import Announcement from './components/announcement/Announcement'

interface IProps extends IConnectProps, WithTranslation {

}
class App extends React.Component<IProps, any>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            showNav: false,
            showTransModal: false,
            showPromptbox: false,
            showSysTip: localStorage.getItem('systemAggress'),
            showAnnouncement: localStorage.getItem('announcement-new')
        }
    }

    componentDidMount(): void {
        // localStorage.setItem("showBool", "true")
        const current_address = this.props.redux.address
        if (current_address) {
            this.validAddress(current_address)
        }
        plusReady(() => {
            this.addEventListener();
            if (isProduction) {
                this.connect();
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any): void {
        const current_address = this.props.redux.address
        if (current_address !== prevProps.redux.address) {
            this.validAddress(current_address)
        }
    }
    async validAddress(address: string) {
        // const res:any = await checkAddressApi(localStorage.getItem('chainId'), address);
        const res = {data: true}

        this.props.setWalletAddress(address);
        // const res = await fetchGet(registeredCheckAPI, { bsc_address: address });
        if (res.data) {
            // this.props.setWalletAddress(address);
            this.setState({ showPromptbox: false })
        } else {
            // window.open("https://pancakeswapmeta.finance/register")
            this.setState({ showPromptbox: true })
            // window.location.href = "https://pancakeswapmeta.finance/register"
        }
        //dispatch.setWalletAddress(result[0]);
    }

    getSnapshotBeforeUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>): any | null {
        const current_trans = this.props.redux.trans;
        if (current_trans.length > 0 && current_trans.length !== prevProps.redux.trans.length) {
            this.checkHashStatus();
        }
        return this.state;
    }
    addEventListener() {
        let ethersProvider = new ethers.providers.Web3Provider(window['ethereum']);
        ethersProvider.getNetwork().then((res: any) => {
            this.checkNetwork(res.chainId);
        });
        window['ethereum'].on('chainChanged', (accounts: any) => {
            // console.log(accounts);
            // console.log(parseInt(accounts, 16));
            let chainID = parseInt(accounts, 16);
            this.checkNetwork(chainID);
        });
        window['ethereum'].on('accountsChanged', (accounts: string[]) => {
            if (accounts && accounts[0]) {
                this.props.setWalletAddress(accounts[0]);

            }
        });
    }

    checkNetwork(ID: number) {
        if (ID !== chainID) {
            // Toast(this.props.t(`请连接正确的网络`));
        }
    }

    connect() {
        window.ethereum.request({
            protocol: "http",
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: `0x${project.chainid.toString(16)}`,
                    chainName: project.chainName || "testxxx",
                    nativeCurrency: {
                        name: project.chainSymbol,
                        symbol: project.chainSymbol,
                        decimals: project.chainSymbolDecimals,
                    },
                    rpcUrls: [project.node],
                    blockExplorerUrls: project.browser,
                },
            ],
        })
    }


    checkHashStatus(transItem?: any) {
        const storeData = this.props.redux;
        let transInfo = transItem || storeData.trans[storeData.trans.length - 1] || {};
        if (transInfo.hash) {
            this.setState({ isPending: true });
            const instance = new ethers.providers.Web3Provider(window['ethereum']);
            instance.getTransactionReceipt(transInfo.hash).then((res) => {
                // this.instance.eth.getTransactionReceipt(transInfo.hash).then((res) => {
                let deleteIndex: number | null = null;
                let updateData: ITrans | undefined;
                let currentHash = '';
                if (res && res.status) {
                    storeData.trans.some((item: any, index) => {
                        if (item.hash === transInfo.hash) {
                            deleteIndex = index;
                            currentHash = item.hash;
                            return true;
                        } else {
                            return false
                        }
                    });
                    if (typeof deleteIndex === 'number') {
                        let _trans = storeData.trans.concat();
                        const delData = _trans.splice(deleteIndex, 1);
                        this.props.setTrans(_trans);
                        this.props.setCompletedHash(currentHash);
                        this.updateLocalTrans(transInfo.hash, { status: 1 });
                    }
                    PubSub.publish("reload.balance",);
                    this.setState({ isPending: false, showTransModal: true });
                } else if (res) {
                    this.updateLocalTrans(transInfo.hash, { status: 2 });
                } else {
                    setTimeout(() => {
                        this.checkHashStatus(transInfo);
                    }, 3 * 1000);
                }
            })
        }
    }

    updateLocalTrans(hash: string, dataInfo: any) {
        let storeData = this.props.redux;
        let updateData: ITrans | undefined;
        storeData.localTrans.some((item: ITrans, index: number) => {
            if (item.hash === hash) {
                let copyItrans = storeData.localTrans.concat();
                updateData = Object.assign({}, item, dataInfo);
                copyItrans.splice(index, 1, updateData as ITrans);
                this.props.setLocalTrans(copyItrans);
                return true;
            }
        });
        if (updateData) {
            let detail = updateData.symbol ? `${updateData.symbol}-${this.props.t(updateData.type)}` : this.props.t(updateData.type);
            //TxNotification(updateData, detail);

            if (updateData.status === 1) {
                let namespace = updateData.symbol;
                PubSub.publish(`reload.balance.${namespace}`,);
            }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <AppStyle className="App" id={"app"}>
                { this.state.showSysTip === null && <SysTip aggressHandler={() => {this.setState({showSysTip: true})}} />}
                { this.state.showSysTip !== null && this.state.showAnnouncement === null && <Announcement aggressHandler={() => {this.setState({showAnnouncement: true})}} />}
                <Toggle vIf={this.state.showPromptbox}>
                    <Promptbox address={this.props.redux.address}></Promptbox>
                </Toggle>
                {/*<Toggle vIf={!isProduction}>
                    <ConnectWallet></ConnectWallet>
                </Toggle>*/}
                <RouterView></RouterView>
                <Toggle vIf={this.state.showTransModal}>
                    <TransModal onClose={() => this.setState({ showTransModal: false })}></TransModal>
                </Toggle>
                
                {/*<Nav></Nav>*/}
            </AppStyle>
        );
    }
}

export default withTranslation()(connect(App));
