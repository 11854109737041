import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import * as style from "./sysTip.style";
import * as stylePC from "./sysTip.pc.style";
import UseWidthChange from "src/hooks/useWidthChange";

export default function SysTip(props:any) {
  const { t, i18n } = useTranslation();
  const { isH5 } = UseWidthChange();
  const { SysTipStyle } = isH5 ? style : stylePC;

  function openHandler(event:any) {
    let openType = 1;
    if(event.target.tagName.toLocaleLowerCase() === 'span'){
      openType = event.target.dataset.type
      const lang = i18n.language
      const _map:any = {
          1: {
              en: 'DefiLabs Privacy Policy.pdf',
              zh: 'DefiLabs 隐私政策.pdf',
          },
          2: {
              en: 'DefiLabs Service Agreement.pdf',
              zh: 'DefiLabs 服务协议.pdf',
          },
      }
      let local = 'en'
      if(lang === 'zh_CN'){
          local = 'zh'
      }
      window.open(`/doc/${_map[openType][local]}`, "_bank")
    }
  }

  function aggressHandler(){
    localStorage.setItem('systemAggress', '1')
    props.aggressHandler()
  }

  return (
    <SysTipStyle>
      <div className="global-sys-tip">
        <p>{t('关于您的隐私')}</p>
        <p onClick={(event) => openHandler(event)} dangerouslySetInnerHTML={{__html: t(`我们使用有关您、您的设备以及您与我们的在线互动的这些信息来提供、分析和改进我们的服务。有关我们如何收集和使用您的个人信息的更多信息，请参阅我们的<span data-type='1'>隐私政策</span>和<span data-type='2'>服务协议</span>。`)}}></p>
        <div className="btn-wrap">
            <button onClick={() => aggressHandler()}>{t('AGREE')}</button>
        </div>
      </div>
    </SysTipStyle>
  );
}
