import styled from "styled-components";

export const AppStyle = styled.div`
    min-height: 100vh;
    font-weight: 400;
    color: #fff;
    text-align: left;
    background: #010101;
    /* padding-bottom: 48px; */
    box-sizing: border-box;
`;
