import { colors, fonts } from "src/styles/style";
import styled from "styled-components";

export const SysTipStyle = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 100000;
    align-items: center;
    justify-content: center;
    .global-sys-tip{
        background: rgb(25, 27, 40);
        width: 90vw;
        border-radius: 8px;
        padding: 30px 20px 40px;
        color: #fff;
        box-sizing: border-box;
        max-height: 90vh;
        overflow: auto;
        p.title{
            line-height: 24px;
            margin-bottom: 20px;
            font-size: 16px;
        }
        p{
            line-height: 24px;
            font-size: 16px;
            // word-break: break-all;
            text-align: center;
            span{
                color: #fff;
                cursor: pointer;
                text-decoration: underline;
                font-weight: bolder;
                font-style: italic;
            }
        }
        .btn-wrap{
            text-align: center;
            button{
                margin-top: 27px;
                border-radius: 12px;
                background: rgb(255, 235, 0);
                max-width: 160px;
                min-width: 133px;
                min-height: 36px;
                border: none;
                font-size: 16px;
                font-weight: 800;
            }
        }
        .content {
            width: 100%;
            p:not(.title) {
                text-align: left;
                padding-bottom: 8px;
            }
        }
    }
`;
