import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from './TransModal.style';
import * as stylePC from './TransModal.pc.style';
import { useStore } from "react-redux";
import { formatAddress } from "../common/utilTools";
import useWidthChange from "src/hooks/useWidthChange";
import { project } from 'src/contract/config';
type IProps = {
    onClose(): void
}
export default function TransModal(props: IProps) {
    const { t } = useTranslation();
    const store = useStore();
    const storeData = store.getState();
    const { isH5 } = useWidthChange();
    const { TransModalStyle } = isH5 ? style : stylePC;

    useEffect(() => {
        let timer = setTimeout(() => {
            props.onClose();
        }, 3 * 1000);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <TransModalStyle>
            <div className="title flex-sb">
                <img src={require("src/assets/images/success.png")} className={"success"} alt="" />
                <div className={"close"} onClick={props.onClose}>
                    <img src={require("src/assets/images/close_modal.png")} className={"icon"} alt="" />
                </div>
            </div>
            <div className="content">
                <div className={"statusText"}>{t("Successful!")}</div>
                <div>{t("Please check your wallet balance.")}</div>
                <a href={`${project.browser}address/${storeData.address}`} target={"_blank"} className={"link"}>
                    <span className={"label"}>{t("View on BscScan:")} </span>
                    <span>{formatAddress(storeData.address)}</span>
                </a>
            </div>
        </TransModalStyle>
    )
}
