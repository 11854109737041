import styled from "styled-components";

const bgColor = "#02D7AC";

export const TransModalStyle = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    border-radius: 8px;
    overflow: hidden;
    background: #212330;
    .title{
        height: 45px;
        padding: 0 24px;
        background: ${bgColor};
    }
    .success{
        width: 23px;
        height: 23px;
    }
    .close{
        padding: 8px;
        .icon{
            width: 9px;
            height: 9px;
            vertical-align: middle;
        }
    }
    .content{
        min-height: 178px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;   
        font-weight: bold;
        text-align: center;
        border: 2px solid ${bgColor};
        border-top: none;
        opacity: 0.95;
        .statusText{
            font-size: 14px;
            font-weight: bold;
        }
        .label{
            color: ${bgColor};
        }
        .link{
            margin-top: 20px;
            font-size: inherit;
            color: #fff;
            text-decoration: none;
        }
    }
`;
