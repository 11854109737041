import { connect } from 'react-redux';
import { IState } from "./reducer";
import { Dispatch } from "redux";
import { ComponentType } from "react";
import * as actions from './actionTypes';
import { ITrans } from "../contract/types";
import { NewReadContract } from "../contract/wallet";
import { project } from "../contract/config";
import { getContractAddress } from '../ajax/index'


const mapStateToProps = (state: IState) => ({
    redux: state
});

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setChain(chainId: string) {
            localStorage.setItem("chainId", chainId);
            dispatch({
                type: actions.SET_CHAINID,
                data: chainId
            })
        },
        setLanguage(language: string) {
            localStorage.setItem("lang", language);
            dispatch({
                type: actions.SET_LANG,
                data: language
            })
        },
        setWalletAddress(address: string) {
            sessionStorage.setItem("wallet_address", address);
            dispatch({
                type: actions.SET_WALLET_ADDRESS,
                data: address
            })
        },
        setTrans(trans: ITrans[]) {
            dispatch({
                type: actions.SET_TRANS,
                data: trans
            })
        },

        setLocalTrans(trans: ITrans[]) {
            sessionStorage.setItem("trans", JSON.stringify(trans));
            dispatch({
                type: actions.SET_LOCAL_TRANS,
                data: trans
            })
        },
        setCompletedHash(hash: string) {
            dispatch({
                type: actions.SET_COMPLETED_HASH,
                data: hash
            })
        },
        async getDenominator(chainId: string) {
            const vPool = project.contracts.vPool;
            const contractAddressMap: any = await getContractAddress()
            vPool.address = {
                "1": contractAddressMap.data.eth,
                "56": contractAddressMap.data.bsc,
                "88": contractAddressMap.data.tro
            }
            const contract: any = NewReadContract(vPool.address[chainId], vPool.abi);
            let denominator;
            if (chainId === '88') {
                const _contranct: any = await (window as any).tronWeb
                    .contract()
                    .at(vPool.address[chainId]);
                // const res = await contract.denominator().call();
                denominator = await _contranct.denominator().call();
            } else {
                denominator = await contract.denominator();
            }
            dispatch({
                type: actions.SET_VPOOL_ADDRESS,
                data: vPool.address
            })
            dispatch({
                type: actions.SET_DENOMINATOR,
                data: denominator.toNumber()
            })
        },
        async setContractAddress() {
            const contractAddressMap: any = await getContractAddress()
            const vpoolAddress = {
                "1": contractAddressMap.data.eth,
                "56": contractAddressMap.data.bsc,
                "88": contractAddressMap.data.tro
            }
            dispatch({
                type: actions.SET_VPOOL_ADDRESS,
                data: vpoolAddress
            })
        }
    }
};

export interface IConnectProps extends ReturnType<typeof mapDispatchToProps> {
    redux: IState
}

export default <p>(component: ComponentType<p>) => {
    return connect(mapStateToProps, mapDispatchToProps)<ComponentType<any>>(component);
}
