import Web3 from "web3";
import { Dispatch } from "redux";
import { chainID, chainNode, erc20, MaxApproveBalance, minAllowance, nft, project } from "./config";
import { BigNumber, ethers, utils } from "ethers";
import { ITrans } from "./types";
import PubSub from "pubsub-js";
import web3 from 'web3';
import { useEffect, useState } from "react";
import { Decimal } from "decimal.js";
import { PROVIDER } from "../config";
import { showMessage } from "src/common/utilTools";
var networks = require('@ethersproject/networks');
var providers = require('@ethersproject/providers');

export function connectWallet(chainId: string) {
    return new Promise(async (resolve, reject) => {
        if (chainId === '88') {
            if ((window as any).tronWeb) {
                if ((window as any).tronLink) {
                    (window as any).tronLink.request({ method: 'tron_requestAccounts' }).then(() => {
                        // dispatch.setWalletAddress((window as any).tronWeb.defaultAddress.base58);
                        resolve((window as any).tronWeb.defaultAddress.base58);
                    })
                } else {
                    resolve((window as any).tronWeb.defaultAddress.base58);
                }
            } else {
                showMessage('Please install TronLink')
                reject()
            }
        } else {
            if (window.ethereum) {
                if (Number(window.ethereum.chainId) !== Number(chainId)) {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{
                            chainId: ethers.utils.hexValue(Number(chainId)) // 目标链ID
                        }]
                    })
                }
                window.ethereum
                    .request({ method: 'eth_requestAccounts' })
                    .then((res: any) => {
                        // console.log(res)
                        resolve(res[0]);
                        (window as any).signer = new ethers.providers.Web3Provider(window.ethereum, 'any').getSigner();
                    })
                    .catch((error: any) => {
                        reject(error)
                    })
            } else {
                showMessage('Please install MetaMask')
                reject()
            }
        }
    })
}

export async function getBalance(address: string) {
    let balance = await getWallet().provider.getBalance(address);
    return Decimal.div(balance.toString(), Math.pow(10, project.chainSymbolDecimals)).toFixed();
}

export async function getBalanceBN(address: string) {
    let balance = await getWallet().provider.getBalance(address);
    return balance;
}

export function getProvider() {
    return new ethers.providers.Web3Provider(window.ethereum, 'any')
    // return ethers.getDefaultProvider(chainNode);
    //return providers.getDefaultProvider(networks.getNetwork(chainID));
}

export function getWallet() {
    return new ethers.providers.Web3Provider(window["ethereum"]).getSigner();
}

export function BscContract(address: string, abi: any[]) {
    return new ethers.Contract(address, abi, ethers.getDefaultProvider(chainNode));
}

export function NewReadContract(address: string, abi: any[]) {
    // if (localStorage.getItem('chainId') === '88') {
    //     return (await (window as any).tronWeb.contract().at(address)) as any
    // } else {
    // }
    return new ethers.Contract(address, abi, getProvider());
}

export function NewWriteContract(address: string, abi: any[]) {
    // if (localStorage.getItem('chainId') === '88') {
    //     return (await (window as any).tronWeb.contract().at(address)) as any
    // } else {
    // }
    return new ethers.Contract(address, abi, getWallet());
}

export function extendTran(data: ITrans, info: { type: string, symbol?: string, status: number }): ITrans {
    let transData = Object.assign({}, data);
    transData.status = info.status;
    transData.type = info.type;
    transData.symbol = info.symbol || "";
    return transData;
}

type IApprove = {
    token: string,
    owner: string,
    spender: string
}
export function needApprove(params: IApprove): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        let contract: any = NewReadContract(params.token, erc20);
        let allowance
        if (localStorage.getItem('chainId') === '88') {
            let _contract: any = await (window as any).tronWeb
                .contract()
                .at(params.token);
            allowance = await _contract.allowance(params.owner, params.spender).call()
        } else {
            allowance = await contract.allowance(params.owner, params.spender)
        }
        if (BigNumber.from(minAllowance).lt(allowance)) {
            resolve(true);
        } else {
            resolve(false);
        }
    });
}

type IApproveNft = {
    token: string,
    spender: string,
    tokenId: number
}
export function needApproveNFT(params: IApproveNft): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        let contract: any = NewReadContract(params.token, nft);
        let spender;
        if (localStorage.getItem('chainId') === '88') {
            let _contract: any = await (window as any).tronWeb.contract().at(params.token);
            spender = await _contract.getApproved(params.tokenId).call();
        } else {
            spender = await contract.getApproved(params.tokenId);
        }
        if (spender === params.spender) {
            resolve(true);
        } else {
            resolve(false);
        }
    });
}


export function approve(params: IApprove): Promise<any> {
    return new Promise(async (resolve, reject) => {
        let contract: any = NewWriteContract(params.token, erc20);
        if (localStorage.getItem('chainId') === '88') {
            let _contract: any = await (window as any).tronWeb.contract().at(params.token);
            _contract.approve(params.spender, MaxApproveBalance).send().then((res: any) => {
                resolve({
                    hash: res
                })
            }).catch((error: any) => {
                // console.log(error)
                reject(error);
            });
        } else {
            contract.approve(params.spender, MaxApproveBalance).then((res: ITrans) => {
                resolve(res)
            }).catch((error: any) => {
                // console.log(error)
                reject(error);
            });
        }
    });
}

export function checkHashStatus(tranInfo: ITrans) {

    function checkStatus(currentHash: string, callback: Function) {
        let instance = new ethers.providers.Web3Provider(PROVIDER);
        //const instance = new web3(chainNode);
        //instance.eth.getTransactionReceipt(currentHash).then((res) => {
        instance.getTransactionReceipt(currentHash).then((res) => {
            if (res && res.status) {
                PubSub.publish("reload.balance");
                callback(true);
            } else if (res) {
                callback(false, res);
            } else {
                setTimeout(() => {
                    checkStatus(currentHash, callback);
                }, 3 * 1000);
            }
        })
    }

    return new Promise((resolve, reject) => {
        if (localStorage.getItem('chainId') === '88') {
            resolve(true)
            return
        }
        checkStatus(tranInfo.hash, (res: boolean, resData: any) => {
            res ? resolve(true) : reject(resData)
        })
    });
}

export function getInput(amount: number | string, decimal: number) {
    return utils.parseUnits(String(amount), decimal);
}

export function isSameAddress(a: string = "", b: string = "") {
    return a.toLowerCase() === b.toLowerCase();
}

