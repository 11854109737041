/* eslint-disable import/no-anonymous-default-export */
import * as actions from './actionTypes';
import { ITrans } from "../contract/types";
import { BrowserLangMap } from "../common/enum"

export type IState = {
    address: string
    trans: ITrans[],
    localTrans: ITrans[],
    completedHash: string,
    lang: string,
    chainId: string,
    denominator: number
    vpoolAddress: any
};

let initState: IState = {
    lang: localStorage.getItem("lang") || BrowserLangMap[(navigator.language || '').toLocaleLowerCase()] || "EN",
    chainId: localStorage.getItem("chainId") || "56",
    address: sessionStorage.getItem("wallet_address") || "",
    trans: [],
    localTrans: sessionStorage.getItem("trans") ? JSON.parse(sessionStorage.getItem("trans") || "{}") : [],
    completedHash: "",
    denominator: 10000,
    vpoolAddress: null
};

export default (state = initState, action: any): any => {
    switch (action.type) {
        case actions.SET_LANG:
            return { ...state, lang: action.data };
        case actions.SET_CHAINID:
            return { ...state, chainId: action.data };
        case actions.SET_WALLET_ADDRESS:
            return { ...state, address: action.data };
        case actions.SET_TRANS:
            return { ...state, trans: action.data };
        case actions.SET_LOCAL_TRANS:
            return { ...state, localTrans: action.data };
        case actions.SET_COMPLETED_HASH:
            return { ...state, completedHash: action.data };
        case actions.SET_DENOMINATOR:
            return { ...state, denominator: action.data };
        case actions.SET_VPOOL_ADDRESS:
            return { ...state, vpoolAddress: action.data }
        default:
            return state;
    }
}
