import i18n from "i18next";
import en_US from "./en_US.json";
import zh_CN from "./zh_CN.json";
import zh_CHT from "./zh_hk.json";
import ja_JP from "./jp.json";
import ko_KR from "./ko.json";
import Id_N from "./yd.json";
import tLand from "./tland.json";
import Russian from './russian.json'
import Arabic from './arabic.json'
import Vietnamese from './vietnamese.json'
import { initReactI18next } from "react-i18next";
import { LanguageType, BrowserLangMap } from "../common/enum";

i18n
  .use(initReactI18next) //init i18next
  .init({
    //引入资源文件
    resources: {
      en_US: {
        translation: en_US,
      },
      zh_CN: {
        translation: zh_CN,
      },
      zh_CHT: {
        translation: zh_CHT,
      },
      ja_JP: {
        translation: ja_JP,
      },
      ko_KR: {
        translation: ko_KR,
      },
      Id_N: {
        translation: Id_N,
      },
      tland: {
        translation: tLand,
      },
      russian: {
        translation: Russian,
      },
      arabic: {
        translation: Arabic,
      },
      vietnamese: {
        translation: Vietnamese,
      },
    },
    //选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: localStorage.getItem("lang") || BrowserLangMap[(navigator.language || '').toLocaleLowerCase()] ||  LanguageType.en,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
